import cn from "classnames";
import { createSignal, Show } from "solid-js";
import styles from "./styles.module.css";

import { CaretDown } from "#icons";

interface Sublink {
  href: string;
  label: string;
}

interface Section {
  heading: string;
  sublinks: Sublink[];
}

interface FooterNavProps {
  sections: Section[];
}

export const FooterNav = ({ sections }: FooterNavProps) => {
  const [openTabs, setOpenTabs] = createSignal<Record<number, boolean>>({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const toggleTabOpen = (i: number) => {
    setOpenTabs((prevTabs) => ({ ...prevTabs, [i]: !prevTabs[i] }));
  };

  return sections.map((section, i) => {
    const { heading, sublinks } = section;
    return (
      <nav class={cn(styles[`fn-${i}`], "border-t md:border-t-0 border-black-200")}>
        <button
          class={cn(
            "w-full flex justify-between items-center pt-5 md:pt-0 md:cursor-default md:pointer-events-none",
            openTabs()[i] ? "pb-0" : "pb-5 md:pb-0",
          )}
          onClick={() => toggleTabOpen(i)}
          name={`Open ${heading} menu`}
          type="button"
        >
          <h3
            class="text-sm font-semibold leading-none uppercase md:text-left"
            data-view-track={heading === "Shop"}
            data-tagular={
              heading === "Shop"
                ? JSON.stringify({
                    event: "ProductViewed",
                    product: {
                      location: "FOOTER_SHOP",
                      text: "SHOP FOOTER",
                      position: 0,
                      brand: "shop",
                    },
                  })
                : undefined
            }
          >
            {heading}
          </h3>
          <Show
            when={openTabs()[i]}
            fallback={
              <CaretDown className="md:hidden text-2xl text-black-300 transform transition-transform" />
            }
          >
            <CaretDown className="md:hidden text-2xl text-black-300 transform transition-transform rotate-180" />
          </Show>
        </button>
        <ul
          class={cn(
            "overflow-hidden md:max-h-full transition-all duration-300",
            {
              "max-h-0": !openTabs()[i],
            },
            !openTabs()[i] ? "max-h-0 pb-0" : "pb-10 lg:pb-0",
            {
              "pb-12 lg:pb-0": i === 3 && openTabs()[3],
            },
          )}
        >
          {sublinks.map((sublink, subIndex) => {
            const { href, label } = sublink;

            return (
              <li>
                <a
                  href={href}
                  class="block mt-6 text-sm text-black-400 md:mt-2"
                  data-tagular={
                    heading === "Shop"
                      ? JSON.stringify({
                          event: "ProductClicked",
                          actionOutcome: "INTERNALLINK",
                          outboundUrl: href,
                          product: {
                            location: "FOOTER_SHOP",
                            position: subIndex + 1,
                            text: label.toUpperCase(),
                            brand: "shop",
                          },
                        })
                      : JSON.stringify({
                          event: "ElementClicked",
                          actionOutcome: "INTERNALLINK",
                          outboundUrl: href,
                          webElement: {
                            location: "FOOTER",
                            elementType: "LINK",
                            position: heading.toUpperCase(),
                            text: label.toUpperCase(),
                          },
                        })
                  }
                >
                  {label}
                </a>
              </li>
            );
          })}
          {sections.length === i + 1 && (
            <>
              <li>
                <button
                  type="button"
                  class="hidden mt-6 font-sans text-sm font-light text-left show-cookie-settings text-black-400 md:mt-2"
                  onClick={() => (window as any).Optanon?.ToggleInfoDisplay()}
                >
                  Cookie Settings
                </button>
              </li>
              <li>
                <a
                  href="https://ninetoy.com/do-not-sell-or-share-my-personal-information"
                  class="block mt-6 text-sm text-black-400 md:mt-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Do Not Sell or Share My Personal Information
                </a>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  });
};